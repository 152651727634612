import React, { useEffect, useState, useRef } from 'react';
import classes from 'components/common/inlineInput/inlineInput.module.scss';
import useDetectClickOutSideComponentHook from 'components/common/CustomHook/DetectClickOutSideComponentHook/useDetectClickOutSideComponentHook';

interface IInlineInputProps {
    placeholder: string;
    value: string;
    handleChange: (value: string) => void;
    isDisabled?: boolean;
    isError?: boolean;
}

const InlineInput = React.forwardRef(
    ({ placeholder, value, handleChange, isDisabled = false, isError = false }: IInlineInputProps, ref) => {
        const [isEdit, setIsEdit] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const {
            ref: clickOutSidRef,
            isComponentVisible,
            setIsComponentVisible,
        } = useDetectClickOutSideComponentHook(isEdit);

        const inputRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            !isComponentVisible && setIsEdit(false);
        }, [isComponentVisible]);

        useEffect(() => {
            if (value !== null) {
                setInputValue(value);
            }
        }, [value]);

        useEffect(() => {
            if (isEdit) {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        }, [isEdit]);

        const toggleEdit = () => {
            if (isDisabled) {
                return;
            }
            setIsEdit(true);
            setIsComponentVisible(true);
        };

        const handleTitle = (e) => {
            setInputValue(e.target.value);
            handleChange(e.target.value);
        };

        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                setIsEdit(false);
                setIsComponentVisible(false);
            }
        };

        return (
            <div
                data-testid={'inline-input-container'}
                className={`${classes.inlineInputContainer} ${isError && classes.error}`}
                ref={clickOutSidRef}
            >
                {isEdit || inputValue?.length === 0 ? (
                    <input
                        ref={inputRef}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleTitle}
                        onClick={toggleEdit}
                        onKeyDown={handleKeyDown}
                    />
                ) : (
                    <div
                        data-testid={'inline-value'}
                        className={`${classes.inlineValue} ${!isDisabled && classes.editable}`}
                        onClick={toggleEdit}
                    >
                        <span>{inputValue}</span>
                    </div>
                )}
            </div>
        );
    },
);

export default InlineInput;
