import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './ganttChartDisplay.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import {
    IActivitiesSummaryResponse,
    IGanttChartResponse,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import { GanttSummary } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttSummary';
import { CustomGantt } from 'components/common/bryntumGantt/gantt';
import { Gantt, TaskModel } from '@bryntum/gantt';
import { useGanttSettingsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/store/settingsStore';
import SwitchComponent from 'components/common/Switch/Switch';
import { mapper, serializeBryntumGanttDependencies } from 'components/common/bryntumGantt/helper';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

import { ganttConfig, projectConfig } from 'components/common/bryntumGantt/GanttConfig';
import { taskTooltipFeature } from 'components/common/bryntumGantt/ganttFeatures';
import NoData from 'components/common/NoData/noData';

const groupViewList: ISelectOption<string>[] = [
    {
        value: 'expandAll',
        label: 'Expand All',
    },
    {
        value: 'collapseAll',
        label: 'Collapse All',
    },
    {
        value: 'default',
        label: 'Collapse top 3 levels',
    },
];

export const progressEnum = {
    SubmittedDuration: 'Submitted % Complete',
    Criticality: 'Criticality % Complete',
};

interface IGanttChartDisplayProps {
    summaryData: IActivitiesSummaryResponse | undefined;
    ganttData: IGanttChartResponse | undefined;
    isLoading: boolean;
    isFullScreen: boolean;
    getInstanceAgain: (gantt: any) => void;
    widgetId?: string;
    progress: string;
    setProgress: React.Dispatch<React.SetStateAction<string>>;
    isCriticalPath: boolean;
    setIsCriticalPath: React.Dispatch<React.SetStateAction<boolean>>;
    isWbs: boolean;
    setIsWbs: React.Dispatch<React.SetStateAction<boolean>>;
    milestoneFragnet?: string;
}

const GanttChartDisplay = ({
    isFullScreen,
    summaryData,
    ganttData,
    isLoading,
    getInstanceAgain,
    widgetId,
    progress,
    setProgress,
    isCriticalPath,
    setIsCriticalPath,
    isWbs,
    setIsWbs,
    milestoneFragnet,
}: IGanttChartDisplayProps) => {
    const { calendarViewRange, showActivityLabels, showProgressLine, showBaseline } = useGanttSettingsStore();

    const ganttInstance = useRef<Gantt | null>(null);

    const getInstance = useCallback((gantt) => {
        if (gantt) {
            ganttInstance.current = gantt;
            getInstanceAgain(gantt);
        }
    }, []);

    const { sendEventWithDimensions } = useEventWithDimensions();
    const [view, setView] = useState<ISelectOption<string>>(
        groupViewList.find((item) => item.value === 'expandAll') as ISelectOption<string>,
    );

    const { versionDate, flatTasks, dependencies } = useMemo(
        () => ({
            versionDate: ganttData?.versionDate,
            flatTasks: ganttData?.activities.map((item) => mapper(item, progress)),
            dependencies: serializeBryntumGanttDependencies(ganttData?.activities),
        }),
        [ganttData],
    );

    const selectProgress = (event) => {
        const selection = event.target.value;
        sendEventWithDimensions({
            category: 'Gantt',
            action: 'radio selection',
            label: selection,
        });
        const instance = ganttInstance.current;
        if (instance) {
            const store = instance.project.taskStore;
            store.forEach((item) => {
                if (selection === progressEnum.Criticality) {
                    item.percentDone = item.percentageCriticalityComplete;
                } else {
                    item.percentDone = item.pComp;
                }
            });
            setProgress(selection);
        }
    };

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.labels.disabled = !showActivityLabels;
        }
    }, [showActivityLabels]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.progressLine.disabled = !showProgressLine;
        }
    }, [showProgressLine]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.baselines.disabled = !showBaseline;
        }
    }, [showBaseline]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.viewPreset = calendarViewRange;
        }
    }, [calendarViewRange]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance && instance.project) {
            const store = instance.project.taskStore;
            if (isCriticalPath) {
                store.filter({
                    id: 'criticalPathFilter',
                    filterBy: (record) => record.criticalPath === true,
                });
            } else {
                store.removeFilter('criticalPathFilter');
            }
        }
    }, [isCriticalPath, flatTasks]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance && instance.project) {
            const store = instance.project.taskStore;
            if (isWbs) {
                store.filter({
                    id: 'wbsFilter',
                    filterBy: (record) => record.type === 'WBS',
                });
            } else {
                store.removeFilter('wbsFilter');
            }
        }
    }, [isWbs, flatTasks]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance && instance.project && flatTasks) {
            const taskIdByTaskVersionHashCode = flatTasks.find((item) => item.taskVersionHashCode === milestoneFragnet);

            if (taskIdByTaskVersionHashCode) {
                const record = instance.taskStore.getById(taskIdByTaskVersionHashCode.id);
                instance
                    .scrollTaskIntoView(record as TaskModel, {
                        block: 'center',
                        edgeOffset: 20,
                        animate: true,
                        highlight: true,
                    })
                    .then(() => instance.selectRow(record));
            }
        }
    }, [milestoneFragnet, flatTasks]);

    const handleStatus = (item: ISelectOption<string> | null) => {
        const action = item?.value;
        const instance = ganttInstance.current;

        if (instance && action) {
            const store = instance.project.taskStore;
            switch (action) {
                case 'expandAll':
                    instance.expandAll();
                    break;
                case 'collapseAll':
                    instance.collapseAll();
                    break;
                case 'default':
                    instance.collapseAll().then(() => {
                        store.forEach((item) => {
                            if (item.childLevel < 3) {
                                instance.expandTo(item.id);
                            }
                        });
                    });
                    break;
            }
            setView(item);
        }
    };

    const controlProps = (item: string) => ({
        checked: progress === item,
        onChange: selectProgress,
        value: item,
        name: 'gantt-progress',
    });

    const zoomIn = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomIn();
        }
    };

    const zoomOut = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomOut();
        }
    };

    return (
        <div className={classes.ganttChartDisplayContainer}>
            <div className={classes.progressSelection}>
                <div className={classes.radioContainer}>
                    {!widgetId && (
                        <div className={classes.progress}>
                            <span>Progress</span>
                            <label>
                                <RadioComponent {...controlProps(progressEnum.Criticality)} />{' '}
                                {progressEnum.Criticality}
                            </label>
                            <label>
                                <RadioComponent {...controlProps(progressEnum.SubmittedDuration)} />{' '}
                                {progressEnum.SubmittedDuration}
                            </label>
                        </div>
                    )}
                    <div className={classes.selectView}>
                        <MyCustomSelect<ISelectOption<string>>
                            value={view}
                            options={groupViewList}
                            onChange={(value) => handleStatus(value)}
                        />
                    </div>
                    {!widgetId && (
                        <div className={classes.critical}>
                            Critical Path Only
                            <SwitchComponent
                                onChange={(e) => setIsCriticalPath(e.target.checked)}
                                checked={isCriticalPath}
                            />
                            WBS Only
                            <SwitchComponent onChange={(e) => setIsWbs(e.target.checked)} checked={isWbs} />
                        </div>
                    )}

                    <MuiIcon icon={'zoom_in'} fontSize={'2.4rem'} onClick={zoomIn} color={'#7489aa'} />
                    <MuiIcon icon={'zoom_out'} fontSize={'2.4rem'} onClick={zoomOut} color={'#7489aa'} />
                </div>
                <GanttSummary data={summaryData} />
            </div>
            <div className={`${classes.container} ${isFullScreen ? classes.fullScreen : ''}`}>
                {isLoading && (
                    <div className={classes.spinnerContainer}>
                        <OverlayWithSpinner />
                    </div>
                )}

                {flatTasks && flatTasks?.length > 0 && versionDate && (
                    <CustomGantt
                        versionDate={versionDate}
                        getInstance={getInstance}
                        flatTasks={flatTasks}
                        dependencies={dependencies}
                        ganttConfig={ganttConfig}
                        projectConfig={projectConfig}
                        taskTooltipFeature={taskTooltipFeature}
                    />
                )}

                {!isLoading && flatTasks && flatTasks?.length === 0 && <NoData />}
            </div>
        </div>
    );
};

export default React.memo(GanttChartDisplay);
