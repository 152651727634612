import React from 'react';
import classes from './MainContent.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import scssVariables from 'styles/variables.module.scss';

interface Props {
    children: React.ReactNode;
}

const { mediaSmallScreen } = scssVariables;

export const MainContent = ({ children }: Props): JSX.Element => {
    const matches = useMediaQuery(`(max-width: ${mediaSmallScreen})`);
    return matches ? (
        <div data-testid={'main-content'} className={classes.mainContent}>{children}</div>
    ) : (
        <div className={classes.mainContentScrooll}>
            <div className={classes.mainContent}>{children}</div>
        </div>
    );
};
