import { create } from 'zustand';
import {
    IPhasePerformanceProject,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { statusOptions } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';

interface IPhasePerformance {
    template: ISelectOption<number> | null;
    selectedProjects: IPhasePerformanceProject[];
    projectsOptions: IPhasePerformanceProject[];
    selectedGates: ISelectOption<number> | null;
    gatesOptions: ISelectOption<number>[];
    selectedTags: ISelectOption<number>[];
    selectedStatus: ISelectOption<IProjectStatus>[];
    setTemplate: (value: ISelectOption<number> | null) => void;
    setSelectedProjects: (value: IPhasePerformanceProject[]) => void;
    setProjectsOption: (value: IPhasePerformanceProject[]) => void;
    setSelectedGates: (value: ISelectOption<number> | null) => void;
    setGatesOptions: (value: ISelectOption<number>[]) => void;
    setSelectedTags: (value: readonly ISelectOption<number>[]) => void;
    setSelectedStatus: (value: readonly ISelectOption<IProjectStatus>[]) => void;
}

const phasePerformanceStore = (set) => ({
    template: null,
    selectedProjects: [],
    projectsOptions: [],
    selectedGates: null,
    gatesOptions: [],
    selectedTags: [],
    selectedStatus: statusOptions,
    setTemplate: (value) => set({ template: value }),
    setSelectedProjects: (value) => set({ selectedProjects: value }),
    setProjectsOption: (value) => set({ projectsOptions: value }),
    setSelectedGates: (value) => set({ selectedGates: value }),
    setGatesOptions: (value) => set({ gatesOptions: value }),
    setSelectedTags: (value) => set({ selectedTags: value }),
    setSelectedStatus: (value) => set({ selectedStatus: value }),
});

export const usePhasePerformanceStore = create<IPhasePerformance>(phasePerformanceStore);
