import { Controller, UseFormReturn } from 'react-hook-form';
import { useQueryActivityCodesByVersion } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import React, { memo, useEffect, useMemo } from 'react';
import { activityCodeMapperUID } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import produce from 'immer';
import { MenuList, MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryState } from 'hooks/useQueryState';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { orderBy } from 'lodash';

interface IActivityCodesFilterProps {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}

interface Option extends ISelectOption<number> {
    alias: string | null;
}

export const ActivityCodesFilter = memo(({ methods, name, setState, handleFilters }: IActivityCodesFilterProps) => {
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;

    const [queryStringValue] = useQueryState('activityCodes');
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<number[]>('activityCodes');

    const { data: activityCodes } = useQueryActivityCodesByVersion({
        latestVersion: latestVersionId,
    });

    const options = useMemo<Option[]>(
        () =>
            activityCodes
                ? orderBy(
                      activityCodes.map(activityCodeMapperUID),
                      [(item: any) => item['label'].toLowerCase()],
                      ['asc'],
                  )
                : [],
        [activityCodes],
    );

    const isValue = useMemo(() => {
        if (queryStringValue && Array.isArray(queryStringValue) && queryStringValue.length > 0) {
            return queryStringValue.map(Number);
        } else {
            return storeValue;
        }
    }, [queryStringValue, storeValue]);

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.activityCodes.isReady = true;
                        draft.activityCodes.isEmpty = true;
                        return draft;
                    });
                });
            }

            if (isValue && activityCodes) {
                const value = activityCodes.map(activityCodeMapperUID).filter((item) => isValue.includes(item.value));
                methods?.setValue(
                    name,
                    value.map((item) => item.value),
                );

                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.activityCodes.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, activityCodes, setState]);

    const handleChange = (value: readonly Option[]) => {
        methods?.setValue(
            name,
            value.map((item) => item.value),
        );

        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        return activityCodes?.map(activityCodeMapperUID).filter((item) => value.includes(item.value)) || [];
    };

    return (
        <div className={classes.selectWrapper}>
            <span className={classes.title}>{FilterLabels.ACTIVITY_CODES}</span>
            <div data-testid={'activity-codes-filter'} className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<Option, true>
                            {...field}
                            options={options}
                            isMulti
                            components={{
                                MenuList: MenuList,
                            }}
                            isOptionDisabled={() => field.value.length >= 3}
                            value={handleValue(field.value)}
                            onChange={handleChange}
                            placeholder={'Select Activity Codes...'}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
});
