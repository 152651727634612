import { CardHeader } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CardHeader/CardHeader';
import { useForm } from 'react-hook-form';
import {
    statusOptions,
    versionMapper,
    wbsMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { useQueryGetBoardInitLite } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import React, { useEffect, useState } from 'react';
import {
    buildCreateCardPayload,
    tabsArrData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.utils';
import classes from './CreateCard.module.scss';
import {
    IFields,
    IInitialCard,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import {
    useMutationCreateCard,
    useMutationGetActivityOptions,
    useMutationGetWBSOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useMutationMainCard';
import {
    IActiveTab,
    TabsSection,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';
import { CreateCardStatusSelect } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCardStatusSelect/CreateCardStatusSelect';
import { CardDetails } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CardDetails/CardDetails';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { CardFooter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardFooter/CardFooter';
import { CustomizedButton } from 'components/common';
import { sortAlphabeticalArray } from 'utilitys/helpers/general';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryActivityDetailsByTaskdId } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityDetailsByTaskdIdQuery';
import { useCommentsEditorStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/zustand_store/battlecardsStore';
import { mapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';

interface ICreateCardProps {
    saveCardCallback: (value?: number) => void;
    initialCard?: IInitialCard;
}

export const CreateCard = ({ saveCardCallback, initialCard }: ICreateCardProps) => {
    const [activeTab, setActiveTab] = useState<IActiveTab>('cardDetails');

    const history = useHistory();
    const { path } = useRouteMatch();
    const { pathname, search } = useLocation();
    const currentVersion = useVersionStore((state) => state.version);
    const latestVersionId = currentVersion?.id;
    const params = useParams<{ id: string }>();
    const { project, contract } = useProjectStore((store) => store.selectedProject);
    const { data: boardInit } = useQueryGetBoardInitLite();
    const { mutate: mutateWBSOptions } = useMutationGetWBSOptions();
    const { mutate: mutateActivityOptions } = useMutationGetActivityOptions();
    const { mutate: mutateCreateCard } = useMutationCreateCard();

    const { handleSnackBar } = useCustomSnackBar();

    const [taskId] = useQueryState('taskId');
    const { data: activityDetails } = useQueryActivityDetailsByTaskdId(taskId);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<IFields>({
        defaultValues: {
            id: null,
            project: null,
            contract: null,
            versionName: null,
            wbsValue: null,
            activityId: null,
            title: '',
            status: statusOptions[0],
            details: '',
            mentionedList: [],
            tagList: [],
            assignee: null,
            category: null,
            isImportant: true,
            startDate: moment().valueOf(),
            endDate: null,
            externalLinks: [],

            relatedCardIds: null,
            type: 'USER_GENERATED',

            // dates by activity id
            cardDates: {
                plannedStartDate: '',
                plannedFinishDate: '',
                calculatedStartDate: '',
                calculatedFinishDate: '',
            },

            // fieldsOptions
            projectOptions: [],
            contractOptions: [],
            versionsOptions: [],
            wbsListOptions: [],
            activityListOptions: [],
            versionId: null,
        },
    });

    useEffect(() => {
        if (boardInit && project && contract && latestVersionId) {
            setValue('versionId', latestVersionId);
            // project
            const currentProject = boardInit.projectContractVersions.find((item) => item.id === project.id);
            setValue('project', mapper(currentProject));
            setValue('projectOptions', boardInit.projectContractVersions.map(mapper));

            // contract
            const contractOptions = currentProject?.contracts;
            const currentContract = contractOptions?.find((item) => item.id === contract.id);
            setValue('contract', mapper(currentContract));
            setValue('contractOptions', contractOptions ? contractOptions.map(mapper) : []);

            // versionName
            const versionOptions = currentContract?.metaDataResponse;
            const currentVersion = currentContract?.metaDataResponse.find((item) => item.id === latestVersionId);
            setValue('versionName', currentVersion ? versionMapper(currentVersion) : null);
            setValue('versionsOptions', versionOptions ? versionOptions.map(versionMapper) : []);

            // category
            const category = boardInit?.category.find((i) => i.name === 'USER_ACTION');
            setValue('category', category ? mapper(category) : null);

            // WBS List Options
            if (currentVersion?.id) {
                mutateWBSOptions(
                    { versionId: currentVersion.id },
                    {
                        onSuccess: (res) => {
                            setValue('wbsListOptions', sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper));
                        },
                    },
                );
            }
        }
    }, [boardInit, project, contract, latestVersionId]);

    // fill Fields by activity details
    useEffect(() => {
        if (boardInit && activityDetails) {
            // project
            const currentProject = boardInit.projectContractVersions.find(
                (item) => item.id === activityDetails.projectId,
            );
            setValue('project', currentProject ? mapper(currentProject) : null);
            setValue('projectOptions', boardInit.projectContractVersions.map(mapper));

            // contract
            const contractOptions = currentProject?.contracts;
            const currentContract = contractOptions?.find((item) => item.id === activityDetails.subProgramId);
            setValue('contract', currentContract ? mapper(currentContract) : null);
            setValue('contractOptions', contractOptions ? contractOptions.map(mapper) : []);

            // versionName
            const versionOptions = currentContract?.metaDataResponse;
            const currentVersion = versionOptions?.find((item) => item.id === activityDetails.versionId);
            setValue('versionName', currentVersion ? versionMapper(currentVersion) : null);
            setValue('versionsOptions', versionOptions ? versionOptions.map(versionMapper) : []);

            // WBS List Options
            if (currentVersion?.id) {
                mutateWBSOptions(
                    { versionId: currentVersion.id },
                    {
                        onSuccess: (res) => {
                            const wbsListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper);
                            const currentWbsValue = wbsListOptions?.find((item) => item.id === activityDetails.wbsId);
                            setValue('wbsValue', currentWbsValue || null);
                            setValue('wbsListOptions', wbsListOptions);
                        },
                    },
                );
            }

            mutateActivityOptions(
                { wbsId: activityDetails.wbsId },
                {
                    onSuccess: (res) => {
                        const activityListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper);
                        const currentActivityId = activityListOptions?.find(
                            (item) => item.id === activityDetails.activityId,
                        );
                        setValue('activityId', currentActivityId || null);
                        setValue('activityListOptions', activityListOptions);
                    },
                },
            );
        }
    }, [boardInit, activityDetails]);

    // fill Fields by initialCard
    useEffect(() => {
        if (initialCard) {
            setValue('title', initialCard.title);
            setValue('details', initialCard.details);
            setValue('category', mapper(initialCard.category));
            setValue('startDate', initialCard.startDate);
            setValue('endDate', initialCard.startDate);
            setValue('assignee', {
                id: null,
                value: 'Unassigned',
                label: 'Unassigned',
                assignee: {
                    id: null,
                    userName: 'Unassigned',
                    firstName: 'Unassigned',
                    lastName: '',
                    imageName: '',
                },
            });
        }
    }, [initialCard]);

    const handleCreate = (data) => {
        const payload = buildCreateCardPayload(data);

        mutateCreateCard(payload, {
            onSuccess: (res) => {
                handleSnackBar('Card Successfully Created', 'success');
                saveCardCallback(res.id);
                const link = `${generatePath(path, { ...params, cardId: res.id })}${search}`;
                history.push(link);
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    const handleSubmitError = () => {
        handleSnackBar('Please fill all mandatory fields', 'error');
    };

    //==================================== handleModalClose ============================================================
    const { resetCommentsEditorStatus } = useCommentsEditorStatus();
    const handleModalClose = () => {
        resetCommentsEditorStatus();
        // remove taskid on close card, so it won't affect other cards creation
        const queryParams = new URLSearchParams(search);
        if (queryParams.has('taskId')) {
            queryParams.delete('taskId');
        }

        const pathWithoutParams = pathname.slice(0, pathname.lastIndexOf('/'));
        history.push({
            pathname: pathWithoutParams,
            search: `?${queryParams.toString().replace(/%2C/g, ',')}`,
        });
    };
    //====================================================================================================

    return (
        <>
            <form onSubmit={handleSubmit(handleCreate, handleSubmitError)} className={classes.createCardContainer}>
                <CardHeader control={control} setValue={setValue} watch={watch} errors={errors} />
                <div className={classes.tabsWithStatusWrapper}>
                    <TabsSection
                        tabs={tabsArrData}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        wrapperClass={classes.tabWrapperClass}
                    />
                    <CreateCardStatusSelect control={control} />
                </div>

                <CardDetails
                    boardInit={boardInit}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    errors={errors}
                />

                <CardFooter>
                    <CustomizedButton
                        size={'large'}
                        color={'secondary'}
                        onClick={handleModalClose}
                        id={'cardModalCloseOrCancelButton'}
                    >
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton type={'submit'} size={'large'} color={'primary'} id={'cardModalSaveButton'}>
                        Save
                    </CustomizedButton>
                </CardFooter>
            </form>
        </>
    );
};
