import { getTitleColorByStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ProgressCard/progressCard.utils';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';
import classes from 'components/common/colorTextByValue/colorTextByValue.module.scss';

export const ColorTextByValue = ({ value, className }: { value: number; className?: string }) => {
    const { themeStatus } = useThemeStatus();
    const color = getTitleColorByStatus({ value, theme: themeStatus });
    return (
        <span style={{ color }} className={`${classes.container} ${className ?? ''}`}>
            {value}
        </span>
    );
};
