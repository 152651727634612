import Pagination from '@mui/material/Pagination';
import React from 'react';
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import colorsVars from 'styles/colors.module.scss';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
const { textColor } = colorsVars;
const theme = createTheme({
    palette: {
        primary: {
            main: '#2C7D90',
            light: alpha('#2C7D90', 0.5),
            dark: '#2C7D90',
        },
    },
});

export type Pagination = {
    page: number;
    size: number;
};

const pageSizeOptions: ISelectOption<number>[] = [
    {
        value: 10,
        label: '10 per page',
    },
    {
        value: 25,
        label: '25 per page',
    },
    {
        value: 50,
        label: '50 per page',
    },
];

interface Props {
    totalPages: number;
    pagination: Pagination;
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
    isDisabled?: boolean;
}

export const CustomPagination = ({ totalPages, pagination, setPagination, isDisabled = false }: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <Pagination
                count={totalPages}
                siblingCount={2}
                page={pagination.page}
                onChange={(e, value) => setPagination({ ...pagination, page: value })}
                color="primary"
                sx={{
                    '.MuiPaginationItem-root': {
                        fontSize: '1.6rem',
                        color: textColor,
                    },
                    '.MuiPaginationItem-icon': {
                        width: '2rem',
                        height: '2rem',
                    },
                    '.Mui-selected': {
                        color: '#ffffff',
                    },
                }}
                disabled={isDisabled}
            />
            <MyCustomSelect<ISelectOption<number>>
                options={pageSizeOptions}
                id={'page-size-options'}
                onChange={(item) => item && setPagination({ page: 1, size: item.value })}
                value={pageSizeOptions.find((item) => item.value === pagination.size) || pageSizeOptions[0]}
                menuPlacement={'top'}
                isDisabled={isDisabled}
            />
        </ThemeProvider>
    );
};
