import classes from 'components/common/Tables/Cells/StatusCell.module.scss';
import {
    IAssignee,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { CardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { AssigneeAvatarWithSelection } from 'components/common/AssigneeAvatarWithSelection/AssigneeAvatarWithSelection';
import { CustomizedStatusSelectWrapper } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelectWrapper/CustomizedStatusSelectWrapper';
import { useMutationChangeAssignee } from 'components/common/AssigneeAvatarWithSelection/queries/useMutationChangeAssignee';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';

interface Props {
    assignee: IAssignee | null;
    wasStatus?: string;
    status: IStatus;
    originalStatus: IStatus;
    isVirtual: boolean;
    taskId: string | number;
    invalidateQueriesKey: string[];
    cardType: CardType;
    category: any;
}
export const StatusCell = ({
    assignee,
    wasStatus,
    status,
    isVirtual,
    taskId,
    originalStatus,
    invalidateQueriesKey,
    cardType,
    category,
}: Props) => {
    const isProgramActivity = cardType === 'PROGRAMME_ACTIVITY';
    const currentVersion = useVersionStore((state) => state.version);
    const { mutate } = useMutationChangeAssignee();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const handleAssigneeSelection = (value) => {
        if (currentVersion) {
            mutate(
                {
                    cardId: taskId,
                    isVirtual: isVirtual,
                    username: value,
                    versionId: currentVersion.id,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('Assignee Successfully Changed', 'success');
                        return Promise.all(invalidateQueriesKey.map((key) => queryClient.invalidateQueries(key)));
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    };

    return (
        <div className={classes.statusCell}>
            <div className={classes.contentWrapper}>
                <CustomizedStatusSelectWrapper
                    category={category}
                    isProgramActivity={isProgramActivity}
                    status={status}
                    taskId={taskId}
                    isVirtual={isVirtual}
                    invalidateQueriesKey={invalidateQueriesKey}
                    originalStatus={originalStatus}
                />

                <AssigneeAvatarWithSelection assignee={assignee} handleSelection={handleAssigneeSelection} />
            </div>
            <div className={classes.wasStatus}>{wasStatus}</div>
        </div>
    );
};
