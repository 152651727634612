import classes from './OptionalFilters.module.scss';
import { CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import React from 'react';
import {
    Control,
    Controller,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { MenuList, OptionsWithAssignee } from 'components/common/MyCustomSelect/myCustomSelect';
import { MilestoneFragnetFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MilestoneFragnetFilter/milestoneFragnetFilter';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import Slider from 'components/common/Slider/Slider';
import {
    getInputValue,
    getInputValueForHasComments,
    hasCommentFixedDateMenuOptions,
    hasCommentsTodayMenuOptions,
    hasCommentsVersionDateMenuOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.utils';
import { DateRangeFilter } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter';
import { FloatFilter } from 'components/common/CustomFilters/FloatFilter/FloatFilter';
import {
    IAssignee,
    ICustomizeFilterOptions,
    IFields,
    IFieldsOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.types';
import { TabOptions } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { DurationFilter } from 'components/common/CustomFilters/DurationFilter/DurationFilter';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';

interface IOptionalFilters {
    customizeFilterOptions: ICustomizeFilterOptions;
    setValue: UseFormSetValue<IFields>;
    control: Control<IFields, IFields>;
    fieldsOptions: IFieldsOptions;
    watch: UseFormWatch<IFields>;
    handleSubmit: UseFormHandleSubmit<IFields>;
    handleApplyFilters: (data: IFields) => void;
    getValues: UseFormGetValues<IFields>;
}

export const OptionalFilters = ({
    customizeFilterOptions,
    setValue,
    control,
    fieldsOptions,
    watch,
    handleSubmit,
    handleApplyFilters,
    getValues,
}: IOptionalFilters) => {
    const methods = { setValue, control, getValues };

    const isCompletionPlanSegmentError = watch('completionPlanSegment') && !watch('activityFragnet');

    const wbsLevelLabelFormat = (value) => {
        return <div>{value === 10 ? `${value}+` : value}</div>;
    };

    const hasCommentsExistingData = {
        type: watch('hasComments').type,
        value: watch('hasComments').value.length === 0 ? watch('flags').commentRange : watch('hasComments').value,
    };

    const handleHasCommentsDate = (data: { value?: string[] | string; type?: TabOptions }) => {
        if (data.type === 'FIXED_DATE') {
            setValue('hasComments', {
                value: Array.isArray(data.value) ? data.value.map(String) : [],
                type: data.type,
            });
            const tempFlags = watch('flags');
            delete tempFlags.commentRange;
            setValue('flags', tempFlags);
        } else {
            setValue('hasComments', { value: [], type: data.type });
            setValue('flags', {
                ...watch('flags'),
                commentRange: data.value as string,
            });
        }

        executeApplyOnSelection();
    };

    // StartDate
    const startDateExistingData = {
        type: watch('startDate').type,
        value: watch('startDate').value.length === 0 ? watch('flags').startDatesRange : watch('startDate').value,
    };

    const handleStartDateSelection = (data: { value?: string[] | string; type?: TabOptions }) => {
        if (data.type === 'FIXED_DATE') {
            setValue('startDate', { value: Array.isArray(data.value) ? data.value.map(String) : [], type: data.type });
            const tempFlags = watch('flags');
            delete tempFlags.startDatesRange;
            setValue('flags', tempFlags);
        } else {
            setValue('startDate', { value: [], type: data.type });
            setValue('flags', {
                ...watch('flags'),
                startDatesRange: data.value as string,
            });
        }

        executeApplyOnSelection();
    };

    // baselineStartDate
    const baselineStartDateExistingData = {
        type: watch('baselineStartDate').type,
        value:
            watch('baselineStartDate').value.length === 0
                ? watch('flags').baselineStartDatesRange
                : watch('baselineStartDate').value,
    };

    const handleBaselineStartDateSelection = (data: { value?: string[] | string; type?: TabOptions }) => {
        if (data.type === 'FIXED_DATE') {
            setValue('baselineStartDate', {
                value: Array.isArray(data.value) ? data.value.map(String) : [],
                type: data.type,
            });
            const tempFlags = watch('flags');
            delete tempFlags.baselineStartDatesRange;
            setValue('flags', tempFlags);
        } else {
            setValue('baselineStartDate', { value: [], type: data.type });
            setValue('flags', {
                ...watch('flags'),
                baselineStartDatesRange: data.value as string,
            });
        }

        executeApplyOnSelection();
    };

    // baselineEndDate
    const baselineEndDateExistingData = {
        type: watch('baselineEndDate').type,
        value:
            watch('baselineEndDate').value.length === 0
                ? watch('flags').baselineEndDatesRange
                : watch('baselineEndDate').value,
    };

    const handleBaselineEndDateSelection = (data: { value?: string[] | string; type?: TabOptions }) => {
        if (data.type === 'FIXED_DATE') {
            setValue('baselineEndDate', {
                value: Array.isArray(data.value) ? data.value.map(String) : [],
                type: data.type,
            });
            const tempFlags = watch('flags');
            delete tempFlags.baselineEndDatesRange;
            setValue('flags', tempFlags);
        } else {
            setValue('baselineEndDate', { value: [], type: data.type });
            setValue('flags', {
                ...watch('flags'),
                baselineEndDatesRange: data.value as string,
            });
        }

        executeApplyOnSelection();
    };

    const executeApplyOnSelection = () => {
        handleSubmit(handleApplyFilters)();
    };

    const handleDurationSelection = (value) => {
        setValue('duration', value);
        executeApplyOnSelection();
    };

    const handleFloatSelection = (value) => {
        setValue('float', value);
        executeApplyOnSelection();
    };

    return (
        <>
            {customizeFilterOptions.baselineStartDate.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Baseline Start Date</span>
                    <DateRangeFilter
                        onSelection={handleBaselineStartDateSelection}
                        existingData={baselineStartDateExistingData}
                        inputDefaultValue={getInputValue(baselineStartDateExistingData)}
                    />
                </div>
            )}

            {customizeFilterOptions.baselineEndDate.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Baseline Finish Date</span>
                    <DateRangeFilter
                        onSelection={handleBaselineEndDateSelection}
                        existingData={baselineEndDateExistingData}
                        inputDefaultValue={getInputValue(baselineEndDateExistingData)}
                    />
                </div>
            )}

            {customizeFilterOptions.activityCodes.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Activity Codes</span>

                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<number> & { alias: string | null }, true>
                                id={'battlecards-filters-activity-codes'}
                                {...field}
                                isMulti
                                options={fieldsOptions.activityCodesOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                                components={{
                                    MenuList: MenuList,
                                }}
                            />
                        )}
                        name={'activityCodes'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.activityFragnet.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Activity Fragnet</span>

                    <Controller
                        render={({ field }) => (
                            <MilestoneFragnetFilter
                                {...field}
                                handle={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                            />
                        )}
                        name={'activityFragnet'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.cardType.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Card Type</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string>, true>
                                id={'battlecards-filters-card-type'}
                                {...field}
                                isMulti
                                options={fieldsOptions.typeCardOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                            />
                        )}
                        name={'cardType'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.completionPlanSegment.isSelected && (
                <>
                    <div className={classes.selectWrapper}>
                        <span className={classes.title}>Completion Plan Segment</span>
                        <Controller
                            render={({ field }) => (
                                <MyCustomSelect<ISelectOption<string>>
                                    id={'battlecards-filters-completion-plan-segment'}
                                    {...field}
                                    options={fieldsOptions.completionPlanSegmentOptions}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        executeApplyOnSelection();
                                    }}
                                    isError={isCompletionPlanSegmentError}
                                    isClearable={true}
                                />
                            )}
                            name={'completionPlanSegment'}
                            control={control}
                        />
                    </div>
                    {isCompletionPlanSegmentError && (
                        <CustomizedFormErrorMessage
                            text={'Completion Plan Segment filter is missing a value for Fragnet'}
                        />
                    )}
                </>
            )}

            {customizeFilterOptions.duration.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Duration</span>
                    <DurationFilter value={watch('duration')} onSelection={handleDurationSelection} />
                </div>
            )}

            {customizeFilterOptions.float.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Float</span>
                    <FloatFilter value={watch('float')} onSelection={handleFloatSelection} />
                </div>
            )}

            {customizeFilterOptions.hasAttachments.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Has Attachments</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string>>
                                id={'battlecards-has-attachments'}
                                {...field}
                                options={fieldsOptions.hasAttachmentsOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                                isClearable={true}
                            />
                        )}
                        name={'hasAttachments'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.hasComments.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Has Comments</span>
                    <DateRangeFilter
                        onSelection={handleHasCommentsDate}
                        existingData={hasCommentsExistingData}
                        inputDefaultValue={getInputValueForHasComments(hasCommentsExistingData)}
                        fixedDateMenuOptions={hasCommentFixedDateMenuOptions}
                        todayMenuOptions={hasCommentsTodayMenuOptions}
                        versionDateMenuOptions={hasCommentsVersionDateMenuOptions}
                    />
                </div>
            )}

            {customizeFilterOptions.lagLead.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Lag/Lead</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string[]>>
                                id={'battlecards-lagLead'}
                                {...field}
                                options={fieldsOptions.lagLeadOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                                isClearable={true}
                            />
                        )}
                        name={'lagLead'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.relatedActivity.isSelected && (
                <div className={classes.selectWrapper}>
                    <Controller
                        render={({ field }) => (
                            <TextSearchInput
                                id={'battlecards-filters-related-activity'}
                                {...field}
                                placeholder={'Related Activity...'}
                                handleClear={() => {
                                    setValue('relatedActivity', '');
                                }}
                                maxLength={300}
                            />
                        )}
                        name={`relatedActivity`}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.resourceType.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Resource Type</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string>, true>
                                id={'battlecards-resource-type'}
                                {...field}
                                isMulti
                                options={fieldsOptions.resourceTypeOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                            />
                        )}
                        name={'resourceType'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.startDate.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Start Date</span>
                    <DateRangeFilter
                        onSelection={handleStartDateSelection}
                        existingData={startDateExistingData}
                        inputDefaultValue={getInputValue(startDateExistingData)}
                    />
                </div>
            )}

            {customizeFilterOptions.submittedCompletion.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Submitted Completion</span>
                    <div className={classes.sliderWrapper}>
                        <Controller
                            render={({ field }) => (
                                <Slider
                                    id={'battlecards-filters-submittedCompletion-slider'}
                                    {...field}
                                    step={10}
                                    min={0}
                                    max={100}
                                    valueLabelDisplay={'on'}
                                    onChangeCommitted={(e, value) => {
                                        field.onChange(value);
                                        executeApplyOnSelection();
                                    }}
                                />
                            )}
                            name={'submittedCompletion'}
                            control={control}
                        />
                    </div>
                </div>
            )}

            {customizeFilterOptions.trackedActivity.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Tracked Activity</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string>>
                                id={'battlecards-tracked-activity-driver'}
                                {...field}
                                options={fieldsOptions.trackedActivityOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                                isClearable={true}
                            />
                        )}
                        name={'trackedActivity'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.updatedBy.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Updated By</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<IAssignee>
                                id={'battlecards-filters-updated-by'}
                                {...field}
                                options={fieldsOptions.updatedByOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                                isClearable={true}
                                components={{
                                    Option: OptionsWithAssignee,
                                }}
                            />
                        )}
                        name={'updatedBy'}
                        control={control}
                    />
                </div>
            )}

            {customizeFilterOptions.wbsLevel.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>WBS Level</span>
                    <div className={classes.sliderWrapper}>
                        <Controller
                            render={({ field }) => (
                                <Slider
                                    id={'battlecards-filters-wbslevel-slider'}
                                    {...field}
                                    step={1}
                                    min={1}
                                    max={10}
                                    valueLabelDisplay={'on'}
                                    onChangeCommitted={(e, value) => {
                                        field.onChange(value);
                                        executeApplyOnSelection();
                                    }}
                                    valueLabelFormat={wbsLevelLabelFormat}
                                />
                            )}
                            name={'wbsLevel'}
                            control={control}
                        />
                    </div>
                </div>
            )}

            {customizeFilterOptions.wbs.isSelected && (
                <div className={classes.selectWrapper}>
                    <WbsFilter
                        name={'wbs'}
                        methods={methods}
                        handleFilters={executeApplyOnSelection}
                        className={classes.wbsFilterCustomClass}
                    />
                </div>
            )}

            {customizeFilterOptions.resourceName.isSelected && (
                <div className={classes.selectWrapper}>
                    <span className={classes.title}>Resource Name</span>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<number> & { count: number }, true>
                                id={'battlecards-resource-name'}
                                {...field}
                                isMulti
                                options={fieldsOptions.resourceNameOptions}
                                onChange={(value) => {
                                    field.onChange(value);
                                    executeApplyOnSelection();
                                }}
                            />
                        )}
                        name={'resourceName'}
                        control={control}
                    />
                </div>
            )}
        </>
    );
};
