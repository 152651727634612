import { cloneDeepWith, isPlainObject, orderBy, toString } from 'lodash';
import {
    IClusterDataClusterActivities,
    IClusterDataDurationDistribution,
    IClusterDataRcfDurationOrRatio,
    IClusterDataResponse,
    IClusterDataStatistics,
    IClustersListItem,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';
import colorsVars from 'styles/colors.module.scss';

const { graphLineColor } = colorsVars;

export const sortClusters = ({
    clustersList,
    sortAscending,
    sortByKey,
}: {
    clustersList: IClustersListItem[];
    sortAscending: boolean;
    sortByKey: (item: IClustersListItem) => string | number;
}): IClustersListItem[] => {
    if (!clustersList) return [];
    return orderBy(clustersList, [sortByKey], [sortAscending ? 'asc' : 'desc']);
};

interface IPrepareDataResponse {
    clusterStatisticsData: IClusterDataStatistics | null;
    durationDistributionData: IClusterDataDurationDistribution | null;
    RCFDurationData: IClusterDataRcfDurationOrRatio[];
    RCFDurationRatioData: IClusterDataRcfDurationOrRatio[];
    ClusterActivitiesData: IClusterDataClusterActivities[];
}

export const prepareData = ({
    clusterData,
}: {
    clusterData: IClusterDataResponse | undefined;
}): IPrepareDataResponse => {
    let clusterStatisticsData: IClusterDataStatistics | null = null;
    let durationDistributionData: IClusterDataDurationDistribution | null = null;
    let RCFDurationData: IClusterDataRcfDurationOrRatio[] = [];
    let RCFDurationRatioData: IClusterDataRcfDurationOrRatio[] = [];
    let ClusterActivitiesData: IClusterDataClusterActivities[] = [];

    if (clusterData?.id) {
        clusterStatisticsData = clusterData.statistics;
        durationDistributionData = clusterData.durationDistribution;
        ClusterActivitiesData = clusterData.data.clusterActivities;
        RCFDurationData = clusterData.rcf.duration;
        RCFDurationRatioData = clusterData.rcf.ratio;

        // put the median that we have in clusterStatisticsData in durationDistributionData if it's missing there
        if (durationDistributionData && !('median' in durationDistributionData.planned)) {
            durationDistributionData.planned.median = clusterStatisticsData?.tableData?.plannedDuration?.median;
            durationDistributionData.actual.median = clusterStatisticsData?.tableData?.actualDuration?.median;
        }
    }
    return {
        clusterStatisticsData,
        durationDistributionData,
        RCFDurationData,
        RCFDurationRatioData,
        ClusterActivitiesData,
    };
};

export const convertValuesToStringsDeep = (obj) => {
    return cloneDeepWith(obj, (value) => {
        return !isPlainObject(value) ? toString(value) : undefined;
    });
};

export const fetchDataSource = () => {
    const { themeStatus } = useThemeStatus();

    return {
        chart: {
            theme: 'candy',
            ynumbersuffix: '',
            showXAxisValues: '1',
            xaxisname: 'Percentile',
            yaxisname: 'Duration Ratio',
            yAxisValueFontColor: '#7687a2',
            yAxisValueFontSize: '14',
            xAxisValueFontSize: '14',
            xAxisNameFontSize: '14',
            yAxisNameFontSize: '14',
            yAxisNameFontColor: '#7687a2',
            xAxisNameFontColor: '#7687a2',
            bgcolor: themeStatus ? '#000000' : '#ffffff',
            labelFontColor: themeStatus ? '#ffffff' : '#000000',
            linethickness: '3',
            anchorImagePadding: '0',
            divLineDashed: '1',
            divLineDashLen: '1',
            divLineDashGap: '1',
            yaxismaxvalue: 6,
            anchorBgColor: '#D3D3D3',
            // anchorimageurl: bulletIconUrl,
            adjustVDiv: 0, // turn off the auto adjust for X axis: https://forum.fusioncharts.com/topic/17494-yaxisminvalue-doesnt-works/
            numVDivLines: 3, // this works
            xAxisMinValue: 0,
            xAxisMaxValue: 100, // this is being ignored, sometimes the data has max=99 and then the plot looks off. maybe make a post on the FusionCharts forum about this
        },
        trendlines: [
            {
                line: [
                    {
                        startValue: '1',
                        endValue: '1',
                        displayValue: ' ',
                        valueonright: '1',
                        color: themeStatus ? '#ffffff' : '#000000',
                        dashed: '1',
                        dashLen: '4',
                        dashGap: '2',
                    },
                ],
            },
        ],
        annotations: {
            groups: [
                {
                    items: [
                        {
                            type: 'text',
                            text: 'ON TIME',
                            fontsize: '9',
                            bold: '1',
                            x: '420',
                            y: '335',
                            fillcolor: themeStatus ? '#ffffff' : '#000000',
                        },
                    ],
                },
            ],
        },
        dataset: [
            {
                drawLine: '1',
                color: themeStatus ? '#ffffff' : graphLineColor,
                anchorradius: '7.5',
                anchorsides: '1',
                anchorborderthickness: '0',
                drawAnchors: '1',
                data: [] as any,
            },
        ],
    };
};

export const findClosestNumber = <T extends Record<string, any>>({
    data,
    target,
    key,
    initial,
}: {
    data: T[] | null;
    target: number;
    key: { [K in keyof T]: T[K] extends number ? K : never }[keyof T];
    initial: number;
}): number => {
    return data
        ? data
              .map((item) => item[key])
              .reduce((prev, curr) => {
                  return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
              }, 0)
        : initial;
};
