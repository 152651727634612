import { Controller, UseFormReturn } from 'react-hook-form';
import React, { memo, useEffect, useMemo } from 'react';
import { useDebounce } from 'hooks/useDebaunce';
import {
    useQueryGetMilestoneFragnet,
    useQueryGetSingleMilestoneFragnet,
} from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import produce from 'immer';
import { milestoneFragnetMapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryState } from 'hooks/useQueryState';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { TooltipIcon } from 'components/common/TooltipIcon/tooltipIcon';

interface IMilestoneFragnetFilterProps {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}

export const MilestoneFragnetFilter = memo(
    ({ methods, name, setState, handleFilters }: IMilestoneFragnetFilterProps) => {
        const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
        const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
        const latestVersionId = versionFromHashMap[0]?.versionDbId;
        const [queryStringValue] = useQueryState('milestoneFragnet');
        const { getValueFromStore } = useGlobalFilterDataStore();
        const storeValue = getValueFromStore<string>('milestoneFragnet');

        const [filter, setFilter] = React.useState('');
        const debouncedValue = useDebounce<string>(filter, 1000);
        const { data: milestoneFragnet, isFetching } = useQueryGetMilestoneFragnet({
            latestVersion: latestVersionId,
            filter: debouncedValue,
        });

        const options = useMemo<ISelectOption<string>[]>(
            () =>
                milestoneFragnet
                    ? milestoneFragnet.map((item) => ({
                          id: item.id,
                          label: `${item.name} (ID: ${
                              item.activity_id.length > 20 ? item.activity_id.slice(0, 20) + '...' : item.activity_id
                          }) (~${item.preceding_activities} related activities)`,
                          value: item.task_version_hash_code,
                      }))
                    : [],
            [milestoneFragnet],
        );

        const formValue = methods?.getValues(name);
        const isValue = formValue || queryStringValue || storeValue;

        const { data: milestoneSingleFragnet } = useQueryGetSingleMilestoneFragnet({
            latestVersion: latestVersionId,
            taskVersionHashCode: isValue, // make sure BE can perform search by task_version_hash_code
        });

        useEffect(() => {
            if (setState) {
                if (!isValue) {
                    setState((prev) => {
                        return produce(prev, (draft) => {
                            draft.milestoneFragnet.isReady = true;
                            draft.milestoneFragnet.isEmpty = true;
                            return draft;
                        });
                    });
                }

                if (milestoneSingleFragnet) {
                    if (milestoneSingleFragnet.length > 0) {
                        methods?.setValue(name, milestoneFragnetMapper(milestoneSingleFragnet[0]).value);
                    }

                    setState((prev) => {
                        return produce(prev, (draft) => {
                            draft.milestoneFragnet.isReady = true;
                            return draft;
                        });
                    });
                }
            }
        }, [isValue, milestoneFragnet, milestoneSingleFragnet, setState]);

        const handleInputChangePrimary = (inputText, event) => {
            if (event.action !== 'input-blur' && event.action !== 'menu-close' && event.action !== 'set-value') {
                setFilter(inputText);
            }
            if (event.action === 'menu-close') {
                setFilter('');
            }
        };

        const handleChange = (value: ISelectOption<string> | null) => {
            methods?.setValue(name, value ? value.value : null);
            handleFilters && handleFilters();
        };

        const handleValue = (value) => {
            if (!value) {
                return null;
            }
            if (
                milestoneSingleFragnet &&
                milestoneSingleFragnet.length > 0 &&
                milestoneFragnetMapper(milestoneSingleFragnet[0]).value === value
            ) {
                return milestoneFragnetMapper(milestoneSingleFragnet[0]);
            }
            return milestoneFragnet?.map(milestoneFragnetMapper).find((item) => item.value === value) || null;
        };

        return (
            <div className={classes.selectWrapper}>
                <span className={classes.title}>
                    {FilterLabels.FRAGNET_SEARCH}{' '}
                    <div className={classes.infoIcon}>
                        <TooltipIcon
                            iconSize={14}
                            size={'2.4rem'}
                            tooltip={{
                                infoList: 'Find specific target activity by starting to type its name or id.',
                                link: null,
                            }}
                        />
                    </div>
                </span>
                <div className={classes.activityCodesSelectWrapper}>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string>>
                                {...field}
                                options={options}
                                id={'global-filter-component-fragnet-milestones'}
                                onInputChange={handleInputChangePrimary}
                                isClearable={true}
                                isLoading={isFetching}
                                onChange={handleChange}
                                value={handleValue(field.value)}
                                placeholder={'Select activity or start typing its name...'}
                            />
                        )}
                        name={name}
                        control={methods?.control}
                    />
                </div>
            </div>
        );
    },
);
