import classes from 'components/common/CustomizedDatePicker/textInput/dateInputWrapper.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React, { useState } from 'react';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';
import constants from 'components/common/Constants/constants';
import moment from 'moment-timezone';
import { IDatePickerProps } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
const { iconColor, errorRedColor } = colorsVars;

interface IDateInputWrapper extends IDatePickerProps {
    value: string;
    handleSelect: (value: Date) => void;
}

export const DateInputWrapper = ({ value, handleSelect, ...rest }: IDateInputWrapper) => {
    const { minDate, maxDate } = rest;

    const [inputValue, setInputValue] = useState(value);
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        isError && setIsError(false);
        setInputValue(e.target.value);
    };

    const handleDatePick = () => {
        const valueAsNumber = moment(inputValue, constants.formats.date.defaultForInput).startOf('day').valueOf();
        const isValidMinDate = minDate ? moment(minDate).startOf('day').valueOf() <= valueAsNumber : true;
        const isValidMaxDate = maxDate ? moment(maxDate).startOf('day').valueOf() >= valueAsNumber : true;

        if (
            moment(inputValue, constants.formats.date.defaultForInput, true).isValid() &&
            isValidMinDate &&
            isValidMaxDate
        ) {
            handleSelect(moment(inputValue, constants.formats.date.defaultForInput).toDate());
        } else {
            setIsError(true);
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            handleDatePick();
        }
    };

    return (
        <div className={classes.dateInputWrapperContainer} data-testid={'dateInputWrapper'}>
            <div className={classes.text}>
                Select date in calendar or <br /> Enter manually in date box in format DD/MM/YYYY
            </div>
            <div className={classes.inputContainer}>
                <div className={classes.inputWrapper}>
                    <TextSearchInput
                        placeholder="DD/MM/YYYY"
                        value={inputValue}
                        onChange={handleChange}
                        handleClear={() => {
                            setInputValue('');
                        }}
                        maxLength={10}
                        onKeyDown={onKeyDown}
                        className={`${isError ? classes.error : ''}`}
                    />
                </div>

                {isError ? (
                    <CustomizedTooltip
                        tooltipContent={<div className={classes.error}>Invalid Input</div>}
                        triggerElement={
                            <div className={`${classes.iconWrapper} ${classes.error}`} onClick={handleDatePick}>
                                <Icon name="info-icon" size="15" color={errorRedColor} />
                            </div>
                        }
                    />
                ) : (
                    <div data-testid={'icon-wrapper'} className={classes.iconWrapper} onClick={handleDatePick}>
                        <Icon name="Governance-top-right-tick" size="15" color={iconColor} />
                    </div>
                )}
            </div>
        </div>
    );
};
