import { useEffect, useMemo, useState } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/automatedClusteringSelection/automatedClusteringSelection.module.scss';
import { SortSelect, TClusterSortBy } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/SortSelect';
import { sortClusters } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';
import {
    COMPARE,
    IClustersListItem,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { useQueryState } from 'hooks/useQueryState';
import { MyCustomSelect } from 'components/common';
import { useClusterRCFAnalysisStore } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/zustand_store/clusterRCFAnalysisStore';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useProjectStore } from 'components/common/TreeProgramFilter/zustand_store/treeHeaderStore';
import { useQueryClustersList } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/queries/clustersQuery';

export const AutomatedClusteringSelection = () => {
    const { id } = useParams<{ id: string }>();
    const { idList } = useHashmapConverter(id);
    const [compare] = useQueryState('clusterScope');
    const { project } = useProjectStore((store) => store.selectedProject);
    const projectId = project?.id;
    const { setSelectedClusterId, setIsIsClustersList } = useClusterRCFAnalysisStore();
    const selectedClusterId = useClusterRCFAnalysisStore((store) => store.selectedClusterId);
    const [clusterIdQuery, setClusterIdQuery] = useQueryState('clusterId');
    const [clusterSortBy, setClusterSortBy] = useState<TClusterSortBy>('completedOverrunRatioTypical');
    const [sortedClusters, setSortedClusters] = useState<IClustersListItem[]>([]);

    const compareKey = Object.keys(COMPARE).find((key) => COMPARE[key] === compare);
    const { data: clustersList } = useQueryClustersList({ idList, projectId, compare: compareKey });

    useEffect(() => {
        if (clustersList) {
            const localSortedClusters = sortClusters({
                clustersList: clustersList.clusterList,
                sortAscending: clusterSortBy === 'clusterName',
                sortByKey:
                    clusterSortBy === 'clusterName'
                        ? (item) => item.clusterName.toLowerCase()
                        : (item) => item[clusterSortBy] ?? -Infinity,
            });

            const currentClusterId = clusterIdQuery
                ? localSortedClusters.find((item) => item.clusterId === Number(clusterIdQuery))
                : localSortedClusters[0];

            const finalCurrentClusterId = currentClusterId ?? localSortedClusters[0];

            setSortedClusters(localSortedClusters);
            setSelectedClusterId(finalCurrentClusterId);

            if (clustersList.clusterList?.length === 0) {
                setIsIsClustersList(false);
            }
        }
    }, [clustersList, clusterSortBy, clusterIdQuery]);

    useEffect(() => {
        if (selectedClusterId) {
            setClusterIdQuery(selectedClusterId?.clusterId);
        }
    }, [selectedClusterId]);

    useEffect(() => {
        return () => {
            setSelectedClusterId(null);
        };
    }, []);

    const handleClusterSortBy = (value: TClusterSortBy) => {
        setClusterSortBy(value);
        setClusterIdQuery(undefined);
    };

    const indexOfSelectedClusterId =
        sortedClusters.findIndex((item) => item.clusterId === selectedClusterId?.clusterId) + 1;

    const clusterSelectTitle = `Select Cluster (${indexOfSelectedClusterId} of ${sortedClusters.length}):`;

    const options = useMemo<ISelectOption<number>[]>(
        () =>
            sortedClusters
                ? sortedClusters.map((item) => ({
                      label: item.clusterName,
                      value: item.clusterId,
                  }))
                : [],
        [sortedClusters],
    );
    const handleChange = (value: ISelectOption<number> | null) => {
        setSelectedClusterId(sortedClusters.find((item) => item.clusterId === value?.value) || null);
    };
    const value = useMemo(
        () => options.find((item) => item.value === selectedClusterId?.clusterId) || null,
        [options, selectedClusterId],
    );

    return (
        <div className={classes.selectClusterRow}>
            <div className={classes.oneSelectorContainer}>
                <h3>{clusterSelectTitle}</h3>
                <MyCustomSelect<ISelectOption<number>>
                    options={options}
                    id={'cluster-select'}
                    onChange={handleChange}
                    value={value}
                />
            </div>

            <div className={classes.oneSelectorContainer}>
                <SortSelect setClusterSortBy={handleClusterSortBy} clusterSortBy={clusterSortBy} />
            </div>
        </div>
    );
};
