import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/programCriticalAnalysis.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import React from 'react';
import { morefilterItems } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import SwitchComponent from 'components/common/Switch/Switch';
export const statusOptions: ISelectOption<string>[] = [
    {
        value: 'TODO',
        label: 'To Do',
    },
    {
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        value: 'Done',
        label: 'Done',
    },
];

export const CriticalAnalysisDelayDriversFilter = ({
    search,
    handleSearch,
    handleClearSearch,
    handleOnSearch,
    onKeyDown,
    dateFilter,
    handleDateFilter,
    months,
    handleStatusType,
    statusFilter,
    handleStatus,
    typeFilter,
    handleCriticalPath,
    isCriticalPath,
}) => {
    return (
        <div className={classes.filterWrapper}>
            <div className={classes.title}>Name</div>
            <div className={classes.filterTextSearch}>
                <TextSearchInput
                    placeholder="Search Activities by Name"
                    value={search}
                    onChange={handleSearch}
                    handleClear={handleClearSearch}
                    maxLength={200}
                    handleClick={handleOnSearch}
                    onKeyDown={onKeyDown}
                    className={classes.textInput}
                />
            </div>

            <div className={classes.selectWrapper}>
                <div className={classes.title}>Month</div>
                <MyCustomSelect<ISelectOption<string>>
                    value={dateFilter}
                    options={months}
                    onChange={handleDateFilter}
                />
            </div>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Status</div>
                <MyCustomSelect<ISelectOption<string>, true>
                    value={statusFilter}
                    options={statusOptions}
                    onChange={handleStatusType}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder={'Select Status...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
            </div>
            <div>
                Critical Path Only
                <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
            </div>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>More Filters</div>
                <MyCustomSelect<ISelectOption<string>, true>
                    value={typeFilter}
                    options={morefilterItems}
                    onChange={handleStatus}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder={'Select More Filters...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
            </div>
        </div>
    );
};
