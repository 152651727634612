import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { Controller } from 'react-hook-form';
import { CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import {
    IAllUserGroups,
    useQueryGetAllUserGroups,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { IUserGroups } from 'components/Settings/Components/UserProfile/userProfile.utils.type';
import useUserHook from 'hooks/useUserHook';
import { useMemo } from 'react';
import { orderBy } from 'lodash';

const groupsMapper = (item: IAllUserGroups) => ({ value: item.group_id, label: item.group_name });

export const UserGroups = ({ errors, control }: IUserGroups) => {
    const { ability } = useUserHook();
    const { data: userGroups } = useQueryGetAllUserGroups({ enabled: ability.can('view', 'UserManagement') });
    const options = useMemo<ISelectOption<number>[]>(() => {
        return userGroups
            ? orderBy(
                  userGroups.map(groupsMapper),
                  [(item: ISelectOption<number>) => item['label'].toLowerCase()],
                  ['asc'],
              )
            : [];
    }, [userGroups]);
    return (
        <div className={classes.associatedRolesContainer}>
            <label>User Groups*</label>
            <Controller
                render={({ field }) => (
                    <MyCustomSelect<ISelectOption<number>, true>
                        {...field}
                        options={options}
                        isClearable={true}
                        isMulti
                        menuPlacement={'auto'}
                    />
                )}
                name="userGroups"
                control={control}
                rules={{
                    validate: {
                        required: (value) => value.length > 0 || 'Must select at least one user groups',
                    },
                }}
            />

            {errors.userGroups && <CustomizedFormErrorMessage text={errors.userGroups.message} />}
        </div>
    );
};
