import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';
import { IActivityCompletionList } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';

const getActivityCompletionPlan = (params) => {
    return network.post(API_URL().GET_ACTIVITY_COMPLETION_PLAN, params).then((response) => {
        return response.data;
    });
};

export const useQueryActivityCompletionPlan = (params) => {
    return useQuery<IActivityCompletionPlanResponse, QueryError>(
        ['activityCompletionPlan', params],
        () => getActivityCompletionPlan(params),
        {
            enabled: Boolean(params?.taskVersionHashCode) && Boolean(params.metaDataId),
        },
    );
};

const exportCompletionPlanToCsv = (params) => {
    const url = API_URL().EXPORT_COMPLETION_PLAN_CSV;

    return network
        .post(url, params.params)
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'text/plain',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${params.fileName}.csv`;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationExportCompletionPlanToCsv = () => {
    return useMutation<unknown, QueryError, any>((params) => exportCompletionPlanToCsv(params));
};

export interface IActivityCompletionPlanResponse {
    activity_completion_list: IActivityCompletionList[];
    blocking_predecessors: number;
    completion_schedule_adherence: {
        actualPercent: number;
        plannedPercent: number;
        scheduleAdherence: number;
    };
    criticality_schedule_adherence: {
        actualPercent: number;
        plannedPercent: number;
        scheduleAdherence: number;
    };
    delaying_predecessors: number;
    info: IToolTip;
    planned_finish_date: number;
    planned_finish_date_change: number | null;
    numOfPages: number;
}
