import React from 'react';
import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import TableTab from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/TableTab/tableTab';
import {
    IAssignee,
    ICardIndicators,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

export type IActivityCompletionList = {
    activity_id: string;
    activity_name: string;
    activity_status: string;
    activity_type: string;
    card_board: ISingleSmallCardResponse;
    days_to_target: number;
    finish_date: number;
    finish_date_change: number;
    blocking: boolean;
    completed: boolean;
    delaying: boolean;
    narrative: string;
    schedule: boolean;
    parentName: string;
    path_length: number;
    remaining_duration: number;
    succeeding_activities: number;
    successors_in_fragnet: number;
    successors_num: number;
};

export type TKeys = 'blocking' | 'delaying' | 'schedule' | 'completed' | 'all';

export const generateTabs = ({ data }): ITab<TKeys>[] => {
    if (data) {
        return [
            {
                tabKey: 'blocking',
                filterKey: 'BLOCKING_FILTER',
                component: <TableTab title={`Blockers (${data.blokersCount})`} className={`red`} />,
            },
            {
                tabKey: 'delaying',
                filterKey: 'DELAYING_FILTER',
                component: <TableTab title={`Delayed (${data.delaydCount})`} className={`yellow`} />,
            },

            {
                tabKey: 'schedule',
                filterKey: 'ON_SCHEDULE_FILTER',
                component: <TableTab title={`On Schedule (${data.onScheduleCount})`} />,
            },

            {
                tabKey: 'completed',
                filterKey: 'COMPLETED_FILTER',
                component: <TableTab title={`Completed (${data.completedCount})`} />,
            },
            {
                tabKey: 'all',
                filterKey: '',
                component: <TableTab title={`All (${data.allCount})`} />,
            },
        ];
    }
    return [];
};

export const getScheduleAdherenceColorClass = (value: number | undefined) => {
    if (!value) return '';
    if (value < 0.9) {
        return 'red';
    } else if (value >= 0.9 && value < 1) {
        return 'amber';
    } else if (value >= 1) {
        return 'green';
    }
    return 'red';
};
