import { Controller, UseFormReturn } from 'react-hook-form';
import useUserHook from 'hooks/useUserHook';
import { useQueryGetTags } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import React, { memo, useEffect, useMemo } from 'react';
import produce from 'immer';
import { MyCustomSelect, OptionsWithExclude } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryState } from 'hooks/useQueryState';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { listStyleWithExclude } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { orderBy } from 'lodash';

interface ITagsFilterProps {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}

interface Value {
    name: string;
    isExclude: boolean;
}

export const TagsFilter = memo(({ methods, name, setState, handleFilters }: ITagsFilterProps) => {
    const [queryStringValue] = useQueryState<ISelectOption<Value>[] | undefined>('tags');

    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<ISelectOption<Value>[]>('tags');

    const { customerId } = useUserHook();
    const { data: tags } = useQueryGetTags({
        customerId,
    });

    const options = useMemo<ISelectOption<Value>[]>(
        () =>
            tags
                ? orderBy(
                      tags.map((item) => ({ label: item.name, value: { name: item.name, isExclude: false } })),
                      [(item: any) => item['label'].toLowerCase()],
                      ['asc'],
                  )
                : [],
        [tags],
    );

    const isValue = useMemo(() => {
        if (queryStringValue && queryStringValue.length > 0) {
            return queryStringValue;
        } else {
            return storeValue;
        }
    }, [queryStringValue, storeValue]);

    useEffect(() => {
        if (setState) {
            if (!isValue || isValue.length === 0) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.tags.isReady = true;
                        draft.tags.isEmpty = true;
                        return draft;
                    });
                });
            }

            if (isValue && isValue.length > 0 && tags) {
                const convertStringToBoolean = isValue.map((item) => {
                    return {
                        ...item,
                        value: {
                            name: item.value.name,
                            isExclude: String(item.value.isExclude).toLowerCase() === 'true',
                        },
                    };
                });
                methods?.setValue(name, convertStringToBoolean);

                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.tags.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, tags, setState]);

    const handleChange = (value: readonly ISelectOption<Value>[]) => {
        methods?.setValue(name, value);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        return orderBy(value, (o) => o.value.name.toLowerCase());
    };

    return (
        <div className={classes.selectWrapper}>
            <span className={classes.title}>{FilterLabels.TAG_CARD_BOARD}</span>
            <div data-testid={'tags-filter'}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<Value>, true>
                            {...field}
                            options={options}
                            isMulti
                            isOptionDisabled={() => field.value.length >= 3}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            placeholder={'Select Tags...'}
                            components={{
                                Option: OptionsWithExclude,
                            }}
                            styles={listStyleWithExclude}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
});
