import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { ITableWidgetWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import NoData from 'components/common/NoData/noData';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import { TableFooter } from 'components/Dashboards/Project/Components/VersionCompare/TableFotter/TableFooter';
import {
    arrayBufferToString,
    generateTabs,
    TKeys,
} from 'components/Dashboards/Project/Components/VersionCompare/utils/helper';
import {
    columns,
    initialState,
} from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDeletedActivities/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { smallPageSize } from 'components/Dashboards/Project/Components/VersionCompare/utils/table.utils';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationExportCsvReport } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataMutation';
import useCustomExcelFileName from 'hooks/useCustomExcelFileName';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { useQueryLargestDeletedActivitiesTableData } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDeletedActivities/queries/useQueryLargestDeletedActivitiesTableData';

const LargestDeletedActivities = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: ITableWidgetWrapperProps) => {
    const tableRef = useRef(null);
    const elementId = 'largest-deleted-activities';
    const id = 'longestDeletedActivities';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    const componentKey = 'largestDeletedActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const [spinnerOpen, setSpinnerOpen] = useState<boolean>(false);
    const mutation = useMutationExportCsvReport();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                  }),

        [filter, globalFilterData],
    );

    const { data, isLoading, isFetching } = useQueryLargestDeletedActivitiesTableData({
        projectId: projectId,
        contractId: contractId,
        latestVersionId: latestVersionId,
        compareVersionId: compareVersionId,
        filterData: {
            filters,
        },
    });

    const title = 'Largest Deleted Activities';
    const reportType = data?.typeId || '';
    const fileName = useCustomExcelFileName({
        projectId,
        contractId,
        latestVersionId,
        title,
        prefix: 'Change_Analysis_',
    });
    const [pageSize, setPageSize] = useState(smallPageSize);
    const [activeTab, setActiveTab] = useState<TKeys>('next');
    const tabsArrData = generateTabs({ data });
    const activeData = useMemo(() => {
        if (data && data.periodItemsList) {
            const periodItemsList = data.periodItemsList;
            return periodItemsList.find((item) => item.id === activeTab)?.itemsList || periodItemsList[0].itemsList;
        }
        return [];
    }, [data, activeTab]);
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const handleAPIExcelExport = () => {
        setSpinnerOpen(true);
        mutation.mutate(
            {
                compareVersionId,
                contractId,
                filter: { filters },
                latestVersionId,
                period: activeTab,
                projectId,
                reportType,
                fileName,
            },
            {
                onSuccess: () => {
                    setSpinnerOpen(false);
                    handleSnackBar('File Successfully Downloaded', 'success');

                    sendEventWithDimensions({
                        category: 'Export',
                        action: 'Excel',
                        label: `${title} ${subTitle}`,
                    });
                },
                onError: (error) => {
                    const message = arrayBufferToString(error?.response?.data);
                    setSpinnerOpen(false);
                    handleSnackBar(message || 'Something went wrong', 'error');
                },
            },
        );
    };
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 && (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    title={title}
                    key={'export'}
                    subTitle={subTitle}
                    handleAPIExcelExport={handleAPIExcelExport}
                />,
            ]}
        >
            {activeData && (
                <div className={classes.tableWrapperContainer}>
                    {tabsArrData.length > 0 && (
                        <CustomizedTabs<TKeys> tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />
                    )}

                    {updatedInitialState && (
                        <MainTable
                            getInstanceCallback={(instance) => (tableRef.current = instance)}
                            data={activeData}
                            columns={columns}
                            initialState={updatedInitialState}
                            pageSize={pageSize}
                            noData={<NoData>No Changes To Display</NoData>}
                            isLoading={isLoading || isFetching}
                        />
                    )}

                    {activeData.length > smallPageSize && <TableFooter setPageSize={setPageSize} pageSize={pageSize} />}
                </div>
            )}
            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
            {spinnerOpen && <OverlayWithSpinner />}
        </WidgetWithTitle>
    );
};
export default React.memo(LargestDeletedActivities);
