import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IClusterItem } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';

interface IClusterStore {
    cluster: IClusterItem | null;
    setCluster: (value: IClusterItem | null) => void;
}

const clusterStore = (set) => ({
    cluster: null,
    setCluster: (value: IClusterItem | null) => set({ cluster: value }),
});

export const useClusterStore = create(
    persist<IClusterStore>(clusterStore, { name: 'sharedResourcesAvailabilityCluster' }),
);
