import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ITopOverdueData,
    useMutationExtractDelayedActivitiesCsv,
    useQueryTopOverdue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/activitiesQuery';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { morefilterItems } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import {
    initialState,
    TOP_OVERDUE_OVERRUN_FILTERS,
    statusFilterInitialState,
    defaultPeriod,
    delayTypeOptions,
    columnsV8,
    TDelayTypeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/TopOverdueOverrunActivitiesComponent/utils';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    filterMapper,
    FilterNames,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { statusOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/criticalAnalysisDelayDriversFilter';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { IComponentProps, IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { List } from 'components/common/ListWrapper/list';
import { useQueryState } from 'hooks/useQueryState';
import { isBetweenRange } from 'components/Dashboards/Project/Components/helper';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import SwitchComponent from 'components/common/Switch/Switch';
import NoData from 'components/common/NoData/noData';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { useReactTableState } from 'hooks/useReactTableState';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { CustomPagination } from 'components/common/pagination/customPagination';

const RenderTopOverdueOverrunActivities = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    localFilters,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({
        initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : initialState.sortBy,
    });
    const [totalPages, setTotalPages] = useState(0);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const id = 'TopOverdueOverrunActivities';
    const componentKey = 'topOverdueOverrunActivities';
    const title = 'Delayed Activities';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const [statusFilter, setStatusFilter] = useState<readonly ISelectOption<string>[]>(statusFilterInitialState);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [status, setStatus] = useState<readonly ISelectOption<string>[]>([]);
    const [delayTypeStatus, setDelayTypeStatus] = useState<ISelectOption<TDelayTypeOptions[]> | null>({
        value: ['FINISH_PROG_OVERDUE_CARD_BOARD'],
        label: 'Overdue Finish Date',
    });
    const [nextFilter, setNextFilter] = useQueryState('nextFilter');
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const numOfMonthsToBeSelected: string = useMemo(() => {
        return widgetId && localFilters?.numOfMonths ? localFilters.numOfMonths.value : nextFilter;
    }, [widgetId, localFilters, nextFilter]);

    const [numOfMonths, setNumOfMonths] = useState<ISelectOption<string> | undefined>(undefined);

    const filters: IFilter[] = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.OPTIONS_STATUS]: statusFilter.map((item) => item.value).map(filterMapper),
                      [FilterNames.OPTIONS_TYPE]: isCriticalPath
                          ? [
                                ...status.map((item) => item.value).map(filterMapper),
                                ...(delayTypeStatus ? delayTypeStatus.value.map(filterMapper) : []),
                                'CRITICAL_PATH',
                            ]
                          : [
                                ...status.map((item) => item.value).map(filterMapper),
                                ...(delayTypeStatus ? delayTypeStatus.value.map(filterMapper) : []),
                            ],
                  }),

        [filter, globalFilterData, statusFilter, status, delayTypeStatus, isCriticalPath],
    );
    const { sendEventWithDimensions } = useEventWithDimensions();

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting, filters]);

    const { data, isLoading, isFetching } = useQueryTopOverdue({
        id: [latestVersionId],
        compareVersionId,
        filter: {
            filters,
        },
        sortingRequestList: sorting,
        paging: { ...pagination, page: pagination.page - 1 },
    });

    const handleStatus = useCallback((value: readonly ISelectOption<string>[]) => {
        const selected = value.map((item) => item.value);
        setStatus(value);

        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: selected.length > 0 ? selected.join(',') : 'Deselect All',
        });
    }, []);

    const handleDelayType = useCallback((value: ISelectOption<TDelayTypeOptions[]> | null) => {
        setDelayTypeStatus(value);
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: value?.label,
        });
    }, []);

    const handleStatusType = (value: readonly ISelectOption<string>[]) => {
        setStatusFilter(value);
    };

    const filteredRows = useMemo(() => {
        return data
            ? data.delayDrivers.filter((item) =>
                  isBetweenRange({
                      numOfMonths: Number(numOfMonthsToBeSelected),
                      startDate: item.currentStartDate,
                      endDate: item.currentFinishDate,
                  }),
              )
            : [];
    }, [data, numOfMonthsToBeSelected]);

    const mergedFilters = useMemo(() => {
        const mainFilters = filter?.filters.mainFilters || [];
        return widgetId && filter?.filters
            ? {
                  filters: [
                      ...mainFilters,
                      ...getFiltersData({
                          [FilterNames.OVERRUN_RISK_SELECTED_PERIOD]:
                              Number(localFilters?.numOfMonths?.value) > 0
                                  ? [`${localFilters?.numOfMonths?.value}M`]
                                  : ['All'],
                      }),
                  ],
              }
            : undefined;
    }, [widgetId, filter, localFilters]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: mergedFilters,
        latestVersionId,
    });

    const handleTimePeriodClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Next Selection',
            label: value,
        });
        setNextFilter(value);
        setNumOfMonths(filter);
    };

    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    useEffect(() => {
        setSubTitleForExport && data && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, data]);

    useEffect(() => {
        if (!nextFilter && !widgetId) {
            setNextFilter(defaultPeriod);
        }
        if (nextFilter && !widgetId) {
            const defaultNumOfMonths = TOP_OVERDUE_OVERRUN_FILTERS.find(
                (filter) => filter.value === numOfMonthsToBeSelected,
            );
            setNumOfMonths(defaultNumOfMonths);
        }
    }, [nextFilter, widgetId, numOfMonthsToBeSelected]);
    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            sortingRequestList: sorting,
        };
    }, [filters, sorting]);

    const mutation = useMutationExtractDelayedActivitiesCsv();
    const handleAPICsvExport = () => {
        mutation.mutate(
            {
                id: latestVersionId ? [latestVersionId] : null,
                compareVersionId,
                filter: {
                    filters,
                },
                fileName: `${title}_${subTitle}`,
            },
            {
                onSuccess: () => {
                    handleSnackBar('File Successfully Downloaded', 'success');

                    sendEventWithDimensions({
                        category: 'Export',
                        action: 'CSV',
                        label: `${title} ${subTitle}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                },
            },
        );
    };

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={data?.info}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : (
                    <div key={'filters'} className={classes.filters}>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Status</div>
                            <MyCustomSelect<ISelectOption<string>, true>
                                value={statusFilter}
                                options={statusOptions}
                                onChange={handleStatusType}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isMulti={true}
                                placeholder={'Select Status...'}
                                components={{
                                    Option: InputOption,
                                    ValueContainer: ValueContainer,
                                }}
                            />
                        </div>
                        <div>
                            Critical Path Only
                            <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Delay Type</div>
                            <MyCustomSelect<ISelectOption<TDelayTypeOptions[]>>
                                value={delayTypeStatus}
                                options={delayTypeOptions}
                                onChange={handleDelayType}
                                placeholder={'Select Delay Type...'}
                            />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>More Filters</div>
                            <MyCustomSelect<ISelectOption<string>, true>
                                value={status}
                                options={morefilterItems}
                                onChange={handleStatus}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isMulti={true}
                                placeholder={'Select More Filters...'}
                                components={{
                                    Option: InputOption,
                                    ValueContainer: ValueContainer,
                                }}
                            />
                        </div>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>{`Next:`}</div>
                            <List<string>
                                onChange={handleTimePeriodClick}
                                options={TOP_OVERDUE_OVERRUN_FILTERS}
                                value={numOfMonths}
                            />
                        </div>
                    </div>
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                    localFilters={{ numOfMonths }}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    key={'export'}
                    title={title}
                    subTitle={subTitle}
                    handleAPICsvExport={handleAPICsvExport}
                />,
            ]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <>
                        <TableV8<ITopOverdueData>
                            data={filteredRows}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching}
                            noData={<NoData>No activities match the applied filter</NoData>}
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />

                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />
        </WidgetWithTitle>
    );
};

export default React.memo(RenderTopOverdueOverrunActivities);
