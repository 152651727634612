import React from 'react';
import 'react-table';

import 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterStatistics/ClusterStatistics.scss';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';

interface IClusterStatisticsProps {
    tableData?: tableDataType | null;
    title?: string;
    tooltip?: IToolTip;
    subTitle: string;
}

export type tableDataType = {
    totalActivities: number;
    completedActivities: number;
    tableData: tableItselfType;
};

type tableItselfType = {
    plannedDuration: {
        mean: number;
        median: number;
        std: number;
    };
    actualDuration: {
        mean: number;
        median: number;
        std: number;
    };
    daysOverrun: {
        mean: number;
        median: number;
        std: number;
    };
    percentOverrun: {
        mean: number;
        median: number;
        std: number;
    };
};

const getDisplayValue = (inputValue) => {
    if (inputValue === null || inputValue === -1) {
        return '-';
    } else {
        return inputValue;
    }
};

const ClusterStatistics = ({ tableData, title = '', tooltip, subTitle }: IClusterStatisticsProps) => {
    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltip}
            titleComponents={[<ExportDropdown title={title} subTitle={subTitle} />]}
            id={'cluster_statistics'}
        >
            <div className="boxWrapper">
                {tableData ? (
                    <div className="clusterStatisticsContainer">
                        <div className="clusterStatisticsText">
                            <p>
                                Activities In Cluster: <span className="highlight">{tableData?.totalActivities}</span>{' '}
                            </p>
                            <p>
                                Completed Activities In Cluster:{' '}
                                <span className="highlight">{tableData?.completedActivities}</span>
                            </p>
                        </div>
                        <StatisticsTable tableData={tableData} />
                    </div>
                ) : (
                    <p>Waiting for data...</p>
                )}
            </div>
        </WidgetWithTitle>
    );
};

export default ClusterStatistics;

const StatisticsTable = ({ tableData }) => {
    return (
        <table id="ClusterStatisticsTable">
            <thead>
                <tr>
                    <th />
                    <th>
                        <div>Typical</div>
                        <div className="textMargin">(P50)</div>
                    </th>
                    <th>
                        <div>High</div>
                        <div className="textMargin">(P80)</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="spacer" />
                <tr className="spacer" />
                <tr className="dark">
                    <td>Planned Duration (Days)</td>
                    <td>{getDisplayValue(tableData?.narrativeData.plannedDuration.pTypical)}</td>
                    <td>{getDisplayValue(tableData?.narrativeData.plannedDuration.pExtreme)}</td>
                </tr>
                <tr className="spacer" />

                <tr className="light">
                    <td>Completed Duration (Days)</td>
                    <td>{getDisplayValue(tableData?.narrativeData.completedDuration.pTypical)}</td>
                    <td>{getDisplayValue(tableData?.narrativeData.completedDuration.pExtreme)}</td>
                </tr>
                <tr className="spacer" />

                <tr className="dark">
                    <td>Completed Overrun (Days)</td>
                    <td>{getDisplayValue(tableData?.narrativeData.overrunDays.pTypical)}</td>
                    <td>{getDisplayValue(tableData?.narrativeData.overrunDays.pExtreme)}</td>
                </tr>
            </tbody>
        </table>
    );
};
