import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { CustomizedTooltip } from 'components/common';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { customSortWithNullV8 } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { ProgramProgressOverviewQueryKey } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import { ColumnDef } from '@tanstack/react-table';
import { ITopOverdueData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/activitiesQuery';

const { color6: orange, delayDriverColor, textColor } = colorsVars;

const invalidateKeys = ProgramProgressOverviewQueryKey;

export const columnsV8: ColumnDef<ITopOverdueData>[] = [
    {
        header: 'Activity Name',
        accessorKey: 'activityName',
        ...columnsCommonConfig.activity,
        cell: ({ row, table }) => {
            const { activityName, activityId, taskVersionHashCode } = row.original;
            const { id, cardIndicators, isTracked, isVirtual } = row.original.cardBoardViewResponse;

            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={id}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'cardBoardViewResponse.status.label',
        ...columnsCommonConfig.status,
        cell: ({ row }) => {
            const { assignee, id, isVirtual, status, originalStatus, category } = row.original.cardBoardViewResponse;
            const cardType = row.original.cardBoardViewResponse.type.name;
            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessorKey: 'cardBoardViewResponse.submittedDurationComplete',
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        id: 'activityType',
        accessorKey: 'activityType',
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['activityType']>();
            return <TooltipCellWithCopy text={value} />;
        },
    },
    {
        header: 'Start Date',
        accessorKey: 'currentStartDate',
        ...columnsCommonConfig.currentStartDate,
        cell: ({ getValue, row }) => {
            const changeMapDate = row.original.cardBoardViewResponse.startVariance;
            const comparedVersion = row.original.baselineStartDate;
            const value = getValue<ITopOverdueData['currentStartDate']>();
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Finish Date',
        accessorKey: 'currentFinishDate',
        ...columnsCommonConfig.currentFinishDate,
        cell: ({ getValue, row }) => {
            const value = getValue<ITopOverdueData['currentFinishDate']>();
            const changeMapDate = row.original.cardBoardViewResponse.finishVariance;
            const comparedVersion = row.original.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Baseline Start Date ',
        accessorKey: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['baselineStartDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Baseline Finish Date',
        accessorKey: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['baselineFinishDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Criticality Score',
        accessorKey: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        cell: ({ getValue, row }) => {
            const delayDriver = row.original.delayDriver;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors;
            const numOfSuccessors = row.original.numOfSuccessors;
            const precedingActivities = row.original.cardBoardViewResponse.precedingActivities;
            const succeedingActivites = row.original.cardBoardViewResponse.succeedingActivites;
            const precedingRisk = row.original.cardBoardViewResponse.precedingRisk;
            const succeedingRisk = row.original.cardBoardViewResponse.succeedingRisk;
            const value = getValue<ITopOverdueData['criticalityScore']>();
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={String(value)}
                />
            );
        },
        sortingFn: 'basic',
    },
    {
        header: 'Float',
        accessorKey: 'float',
        ...columnsCommonConfig.float,
        sortingFn: customSortWithNullV8,
    },
    {
        header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessorFn: (row) => (row.criticalPath ? 'Yes' : 'No'),
    },
    {
        header: 'Immediate Parent Name ',
        accessorKey: 'parentName',
        ...columnsCommonConfig.parentName,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['parentName']>();
            return <TooltipCellWithCopy text={value} />;
        },
        enableSorting: false,
    },
    {
        header: 'Details/Last Comment',
        accessorKey: 'cardBoardViewResponse.details',
        ...columnsCommonConfig.details,
        cell: ({ row }) => {
            const { details, cardLastComment, id, isVirtual } = row.original.cardBoardViewResponse;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    isVirtual={isVirtual}
                    invalidateQueriesKey={invalidateKeys}
                />
            );
        },
        enableSorting: false,
    },

    {
        header: 'Planned Start Date ',
        accessorKey: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['plannedStartDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Planned Finish Date',
        accessorKey: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['plannedFinishDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Actual Start Date',
        accessorKey: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['actualStartDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Actual Finish Date',
        accessorKey: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['actualFinishDate']>();
            return <BasicDateCell value={value} />;
        },
    },

    {
        header: 'Planned Duration',
        accessorKey: 'plannedDuration',
        ...columnsCommonConfig.plannedDuration,
    },
    {
        header: 'Current Duration Overrun',
        accessorKey: 'durationOverun',
        ...columnsCommonConfig.durationOverrun,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['durationOverun']>();
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        header: 'Actual Duration',
        accessorKey: 'actualDuration',
        ...columnsCommonConfig.actualDuration,
    },
    {
        header: 'Remaining Duration',
        accessorKey: 'remainingDuration',
        ...columnsCommonConfig.remainingDuration,
    },
    {
        header: 'Current Duration',
        accessorKey: 'completedDuration',
        ...columnsCommonConfig.completedDuration,
    },
    {
        header: 'Completed Duration Overrun',
        accessorKey: 'overrunDays',
        ...columnsCommonConfig.overrunDays,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['overrunDays']>();
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        header: 'Completed Overrun %',
        accessorKey: 'overrunPercent',
        ...columnsCommonConfig.overrunPercent,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['overrunPercent']>();
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        header: 'Pred.',
        accessorKey: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        header: 'Succ.',
        accessorKey: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        header: 'Project Week Start ',
        accessorKey: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        header: 'Project Week Finish',
        accessorKey: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        header: 'Project Month Start',
        accessorKey: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        header: 'Project Month Finish',
        accessorKey: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },

    {
        header: 'Grand Parent Name',
        accessorKey: 'grandParentName',
        ...columnsCommonConfig.grandParentName,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['grandParentName']>();
            return <TooltipCellWithCopy text={value} />;
        },
        enableSorting: false,
    },
    {
        header: 'Start Variance',
        accessorKey: 'cardBoardViewResponse.startVariance',
        ...columnsCommonConfig.lateStart,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['cardBoardViewResponse']['startVariance']>();
            return <VarianceCell value={value} />;
        },
        sortingFn: 'basic',
        enableSorting: false,
    },
    {
        header: 'Finish Variance',
        accessorKey: 'cardBoardViewResponse.finishVariance',
        ...columnsCommonConfig.lateFinish,
        cell: ({ getValue }) => {
            const value = getValue<ITopOverdueData['cardBoardViewResponse']['finishVariance']>();
            return <VarianceCell value={value} />;
        },
        enableSorting: false,
    },
];

export const initialState: ITableColumnState = {
    hiddenColumns: ['currentStartDate', 'baselineStartDate', 'lateStart', 'plannedDuration', 'durationOverrun'],
    sortBy: [{ id: 'criticalityScore', desc: true }],
    columnOrder: [
        'activity',
        'status',
        'submittedDurationComplete',
        'activityType',
        'currentStartDate',
        'currentFinishDate',
        'baselineStartDate',
        'baselineFinishDate',
        'criticalPath',
        'float',
        'criticalityScore',
        'parentName',
        'details',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'durationOverrun',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'overrunDays',
        'overrunPercent',
        'numOfPredecessors',
        'numOfSuccessors',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'grandParentName',
        'lateStart',
        'lateFinish',
    ],
};

export const TOP_OVERDUE_OVERRUN_FILTERS: ISelectOption<string>[] = [
    {
        label: '3M',
        value: '3',
    },
    {
        label: '6M',
        value: '6',
    },
    {
        label: '12M',
        value: '12',
    },
    {
        label: 'ALL',
        value: '-1',
    },
];

export const statusFilterInitialState = [
    {
        id: 1,
        value: 'TODO',
        label: 'To Do',
    },
    {
        id: 2,
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
];

export const defaultPeriod = '-1';

export type TDelayTypeOptions = 'FINISH_PROG_OVERDUE_CARD_BOARD' | 'START_PROG_OVERDUE_CARD_BOARD';

export const delayTypeOptions: ISelectOption<TDelayTypeOptions[]>[] = [
    { value: ['FINISH_PROG_OVERDUE_CARD_BOARD'], label: 'Overdue Finish Date' },
    { value: ['START_PROG_OVERDUE_CARD_BOARD'], label: 'Overdue Start Date' },
    {
        value: ['START_PROG_OVERDUE_CARD_BOARD', 'FINISH_PROG_OVERDUE_CARD_BOARD'],
        label: 'Overdue Start Or Finish Dates (Both)',
    },
];
