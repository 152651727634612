import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/WhiskerPlot/Whiskerplot.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { convertValuesToStringsDeep } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';
import { useThemeStatus } from 'components/Header/zustand_store/themeStatus';

type WhiskerPlotProps = {
    plotData?: TWhiskerPlotData;
    title?: string;
    tooltipArg?: IToolTip;
    subTitle: string;
};

export type TWhiskerPlotData = null | {
    planned: whiskerPlotSeries;
    actual: whiskerPlotSeries;
};

type whiskerPlotSeries = {
    min?: number;
    firstQuartile?: number;
    median?: number;
    mean?: number;
    thirdQuartile?: number;
    max?: number;
    outliers?: number[];
};

// Known Issues:
// * no vertical line in the middle of the plot like in the design, because adding "vline": "true" makes data disappear, bug?
// * line where x=0 doesnt work, showZeroPlaneValue: "1" does nothing

// API ref for boxandwhisker2d is here: https://www.fusioncharts.com/dev/chart-attributes/boxandwhisker2d

const WhiskerPlot = ({ plotData, tooltipArg, subTitle }: WhiskerPlotProps) => {
    ReactFC.fcRoot(FusionCharts, Charts, CandyTheme);

    // transform the data from the API-format to the format the graph wants
    let transformedData;
    if (plotData?.planned) {
        transformedData = {
            planned: {
                min: plotData.planned.min,
                q1: plotData.planned.firstQuartile,
                median: plotData.planned.hasOwnProperty('median')
                    ? plotData.planned?.median
                    : plotData.planned.firstQuartile,
                mean: plotData.planned.hasOwnProperty('mean') ? plotData.planned?.mean : '',
                q3: plotData.planned.thirdQuartile,
                max: plotData.planned.max,
                outliers: plotData.planned.outliers ? plotData.planned.outliers.join(', ') : '',
            },
            actual: {
                min: plotData.actual.min,
                q1: plotData.actual.firstQuartile,
                median: plotData.actual.hasOwnProperty('median')
                    ? plotData.actual?.median
                    : plotData.actual.firstQuartile,
                mean: plotData.actual.hasOwnProperty('mean') ? plotData.actual?.mean : '',
                q3: plotData.actual.thirdQuartile,
                max: plotData.actual.max,
                outliers: plotData.actual.outliers ? plotData.actual.outliers.join(', ') : '',
            },
        };
    }

    type TdataSource = {
        chart: {};
        categories: any[];
        dataSet: any[];
    };

    const { themeStatus } = useThemeStatus();

    let fusionChartsConfig: TdataSource = {
        chart: {
            theme: 'candy',
            baseFont: 'Roboto',
            outCnvBaseFont: 'Roboto',
            baseFontColor: '#00ff00',
            YAxisName: 'Time (days)',
            legendBorderAlpha: '0',
            showValues: '0',
            toolTipColor: '#ffffff',
            toolTipBorderThickness: '0',
            toolTipBgColor: '#000000',
            toolTipBgAlpha: '80',
            toolTipBorderRadius: '2',
            toolTipPadding: '5',
            plotSpacePercent: '80', // makes the bars smaller
            bgcolor: themeStatus ? '#000000' : '#ffffff',
            labelFontColor: themeStatus ? '#ffffff' : '#000000',
            divLineDashed: '1',
            divLineDashLen: '1',
            divLineDashGap: '1',
            showcanvasborder: '1',
            canvasBorderThickness: 1,
            canvasBorderAlpha: 50,
            xAxisNameFontSize: '32',
            yAxisNameFontSize: '14',
            showmean: '1',
            outlierIconRadius: '3',
            outlierIconColor: '#ffffff',
            outlierIconAlpha: '100',
            meaniconsides: 6,
            meanIconColor: '#ffffff',
            meanIconAlpha: '100',
            showlegend: '0',
            medianColor: themeStatus ? '#ffffff' : '#000000',
            medianThickness: '2',
        },
        categories: [
            {
                category: [
                    {
                        label: 'Planned',
                        fontSize: '16',
                    },
                    {
                        label: 'Completed',
                        fontSize: '16',
                    },
                ],
            },
        ],
        dataSet: [
            {
                data: [{}, {}],
            },
        ],
    };

    // Put our prop-given data set in the plot config
    if (plotData && transformedData) {
        const isPlanned = !Object.values(transformedData.planned).some((i) => i === null);
        const isActual = !Object.values(transformedData.actual).some((i) => i === null);
        fusionChartsConfig.dataSet[0].data[0] = isPlanned ? convertValuesToStringsDeep(transformedData.planned) : {};
        fusionChartsConfig.dataSet[0].data[1] = isActual ? convertValuesToStringsDeep(transformedData.actual) : {};
    }

    const whiskerPlotConfig = {
        type: 'boxandwhisker2d',
        width: '100%',
        height: 430,
        dataFormat: 'json',
        dataSource: fusionChartsConfig,
    };

    const title = 'Cluster Duration Distribution';

    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipArg}
            titleComponents={[<ExportDropdown key={'export'} title={title} subTitle={subTitle} />]}
        >
            <div className={classes.boxWrapper}>
                {plotData?.planned && <ReactFC {...whiskerPlotConfig} />}
                {!plotData?.planned && <p className={classes.text}>Waiting for data...</p>}
            </div>
        </WidgetWithTitle>
    );
};

export default WhiskerPlot;
