import colorsVars from 'styles/colors.module.scss';
import { get } from 'lodash';
const { todoCardBackground, doneCardBackground, inProgressCardBackground, notFoundBackground } = colorsVars;

export const customSortByLatestVersion = (rowA, rowB, id) => {
    const sourceA = rowA.original.latestVersion || rowA.original.comparedVersion;
    const sourceB = rowB.original.latestVersion || rowB.original.comparedVersion;
    if (sourceA && sourceB) {
        if (sourceA[id] === null) return 1;
        if (sourceB[id] === null) return -1;
        return sourceB[id] - sourceA[id];
    }
};

export const customSortByChangeMap = (rowA, rowB, id) => {
    if (rowA.original.changeMap && rowB.original.changeMap) {
        if (rowA.original.changeMap[id] === null) return 1;
        if (rowB.original.changeMap[id] === null) return -1;
        return rowB.original.changeMap[id] - rowA.original.changeMap[id];
    }
};

export const customSortWithNullV8 = (rowA, rowB, id) => {
    const valueA = rowA.getValue(id);
    const valueB = rowB.getValue(id);
    if (valueA === null) return 1;
    if (valueB === null) return -1;
    return valueA - valueB;
};

export const customSortWithNull = (rowA, rowB, id) => {
    const valueA = rowA.values[id];
    const valueB = rowB.values[id];
    if (valueA === null) return 1;
    if (valueB === null) return -1;
    return valueA - valueB;
};

export type Status = 'TODO' | 'DONE' | 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETED';

export const getStatusColor = (param: Status | undefined | string) => {
    switch (param) {
        case 'TODO':
        case 'To Do':
            return 'toDo';
        case 'DONE':
        case 'Done':
            return 'done';
        case 'IN_PROGRESS':
        case 'In Progress':
            return 'inProgress';
        case 'NOT_STARTED':
            return 'notStarted';
        case 'COMPLETED':
            return 'completed';
        default:
            return 'notFound';
    }
};

export const getStatusLabel = (param: Status | undefined) => {
    switch (param) {
        case 'TODO':
            return 'To Do';
        case 'DONE':
            return 'Done';
        case 'IN_PROGRESS':
            return 'In Progress';
        case 'NOT_STARTED':
            return 'To Do';
        case 'COMPLETED':
            return 'Done';
        default:
            return 'No Change';
    }
};

export const getStatusColorHex = (status: Status): string => {
    switch (status) {
        case 'TODO':
            return todoCardBackground;
        case 'DONE':
            return doneCardBackground;
        case 'IN_PROGRESS':
            return inProgressCardBackground;
        case 'NOT_STARTED':
            return todoCardBackground;
        case 'COMPLETED':
            return doneCardBackground;
        default:
            return notFoundBackground;
    }
};

export const getStatusColorHexByLaneId = (laneId: string): string => {
    switch (laneId) {
        case '1':
            return todoCardBackground;
        case '2':
            return inProgressCardBackground;
        case '3':
            return doneCardBackground;
        default:
            return todoCardBackground;
    }
};
